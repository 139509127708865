import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';


export interface BankQueryParams {
  pageSize?: number
  pageNumber?: number
}

export interface RemitBanks{
  body: RemitBank[];
  totalData: number;
}

export interface RemitBank{
  bankCode: string
  bankName: string
  swiftCode: string
}

export interface SwiftCodes{
  body: SwiftCode[]
  totalData: number
}

export interface SwiftCode{
  swiftCode: string
  legalName: string
  name: string
}


@Injectable({
  providedIn: 'root',
})
export class BankCodeService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }


  queryBanks(params: BankQueryParams): Observable<RemitBanks> {
    return this.http.post<RemitBanks>(
        this.remitApiUrl + '/staff/bankcodes',
        params,
    );
  }

  createBank(bankInfo: RemitBank): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/bankcode',
        bankInfo,
    );
  }
  updateBank(id: string, bankInfo: Partial<RemitBank>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/bankcode/${id}`,
        bankInfo,
    );
  }

  querySwiftCodes(): Observable<SwiftCodes> {
    return this.http.post<SwiftCodes>(
        this.remitApiUrl + '/staff/swift-codes',
        {},
    );
  }
}


