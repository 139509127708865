import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {RemitShopService} from '@indosuara/remit-api';
import {map, startWith, switchMap} from 'rxjs';


@Component({
  selector: 'indosuara-shop-item-lookup',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
  ],
  templateUrl: './shop-item-lookup.component.html',
  styleUrls: ['./shop-item-lookup.component.css'],
})
export class ShopItemLookupComponent {
  private selectedItemId: string[];

  constructor(
    public dialogRef: MatDialogRef<ShopItemLookupComponent, string[]>,
    private service: RemitShopService,
  ) {
    this.products$ = this.params.valueChanges.pipe(
        startWith({}),
        switchMap(()=>{
          return this.service.queryShopItems(this.params.value);
        }),
    );

    this.itemTypes$ = this.service.getShopItemTypes();


    this.itemTags$ = this.service.queryItemTags({
      pageNumber: 0,
      pageSize: 100,
    }).pipe(
        map((v)=> v.body),
    );

    this.selectedItemId = [];
  }

  products$;

  itemTypes$;
  itemTags$;

  params = new FormGroup({
    pageNumber: new FormControl<number>(0, {nonNullable: true}),
    pageSize: new FormControl<number>(10, {nonNullable: true}),
    isDeleted: new FormControl<'Y'|'N'>('N', {nonNullable: true}),
    name: new FormControl('', {nonNullable: true}),
    itemType: new FormControl('', {nonNullable: true}),
    tags: new FormControl<string[]>([], {nonNullable: true}),
  });


  onPaginateChange(event: PageEvent) {
    this.params.patchValue({
      pageNumber: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  select(id: string) {
    this.selectedItemId.push(id);
  }

  deselect(id: string) {
    this.selectedItemId = this.selectedItemId.filter((v) => v !== id);
  }

  isItemSelected(id: string) {
    return this.selectedItemId.includes(id);
  }

  confirmAddItem() {
    this.dialogRef.close(this.selectedItemId);
  }
}
